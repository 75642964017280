import $n2es6$widgets$N2FilterableLegendWidget from './n2es6/widgets/N2FilterableLegendWidget';
import $n2es6$widgets$N2ModelExportWidget from './n2es6/widgets/N2ModelExportWidget';
import $n2es6$ol5support$N2DonutCluster from './n2es6/ol5support/N2DonutCluster';
import $n2es6$n2mapModule$N2CouchDbSource from './n2es6/n2mapModule/N2CouchDbSource';
import $n2es6$n2mapModule$N2CustomLineStyle from './n2es6/n2mapModule/N2CustomLineStyle';
import $n2es6$n2mapModule$N2CustomPointStyle from './n2es6/n2mapModule/N2CustomPointStyle';
import $n2es6$n2mapModule$N2LayerInfo from './n2es6/n2mapModule/N2LayerInfo';
import $n2es6$n2mapModule$N2MapCanvas from './n2es6/n2mapModule/N2MapCanvas';
import $n2es6$n2mapModule$N2MapScale from './n2es6/n2mapModule/N2MapScale';
import $n2es6$n2mapModule$N2MapSpy from './n2es6/n2mapModule/N2MapSpy';
import $n2es6$n2mapModule$N2ModelSource from './n2es6/n2mapModule/N2ModelSource';
import $n2es6$n2mapModule$N2Select from './n2es6/n2mapModule/N2Select';

var n2es6 = {};

n2es6.n2mapModule = {};
n2es6.ol5support = {};
n2es6.widgets = {};
n2es6.n2mapModule.N2CouchDbSource = $n2es6$n2mapModule$N2CouchDbSource;
n2es6.n2mapModule.N2CustomLineStyle = $n2es6$n2mapModule$N2CustomLineStyle;
n2es6.n2mapModule.N2CustomPointStyle = $n2es6$n2mapModule$N2CustomPointStyle;
n2es6.n2mapModule.N2LayerInfo = $n2es6$n2mapModule$N2LayerInfo;
n2es6.n2mapModule.N2MapCanvas = $n2es6$n2mapModule$N2MapCanvas;
n2es6.n2mapModule.N2MapScale = $n2es6$n2mapModule$N2MapScale;
n2es6.n2mapModule.N2MapSpy = $n2es6$n2mapModule$N2MapSpy;
n2es6.n2mapModule.N2ModelSource = $n2es6$n2mapModule$N2ModelSource;
n2es6.n2mapModule.N2Select = $n2es6$n2mapModule$N2Select;
n2es6.ol5support.N2DonutCluster = $n2es6$ol5support$N2DonutCluster;
n2es6.widgets.N2FilterableLegendWidget = $n2es6$widgets$N2FilterableLegendWidget;
n2es6.widgets.N2ModelExportWidget = $n2es6$widgets$N2ModelExportWidget;

export default n2es6;